import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { OurTeamService } from 'src/app/services/our-team/our-team.service';
import { CryptService } from 'src/app/services/crypt.service';
import {MatDialog} from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
year=new Date().getFullYear();
  lang
  nameLangchange: string
  title: string = ''
  MenuMain: any = []
  panelOpenState = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  setHeader() {
    this.title = this.c.Decrypt(sessionStorage.getItem('brnTitle'))
  }


  
  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent);

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  constructor(public dialog: MatDialog,private c: CryptService, private ser: OurTeamService, private breakpointObserver: BreakpointObserver, public translate: TranslateService, @Inject(DOCUMENT) private document: Document, private router: Router) {

    this.title = this.c.Decrypt(sessionStorage.getItem('brnTitle'))
    this.changeLangage(this.c.Decrypt(sessionStorage.getItem('syslang')))

  }

  logout() {
    sessionStorage.removeItem('brnId')
    sessionStorage.removeItem('userbrnId')
    sessionStorage.removeItem('sysid')
    sessionStorage.removeItem('syslang')
    sessionStorage.removeItem('usrFullName')
    sessionStorage.removeItem('usrId')
    sessionStorage.removeItem('brnTitle')
    sessionStorage.removeItem('UserbrnTitle')
    this.router.navigateByUrl('login')
  }

  changeLangage(lang: string) {
     sessionStorage.setItem('syslang', this.c.Encrypt(lang))
    this.ser.GetMenu('', '', this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data => {
      this.MenuMain = data

    })

    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "Ar" ? "rtl" : "ltr";
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);

  }

  d(routeComponent) {
    if (routeComponent == 'dashboard') {
      sessionStorage.setItem('brnId', this.c.Encrypt(this.c.Decrypt(sessionStorage.getItem('userbrnId'))))
      sessionStorage.setItem('brnTitle', this.c.Encrypt(this.c.Decrypt(sessionStorage.getItem('UserbrnTitle'))))
      this.getM(this.c.Decrypt(sessionStorage.getItem('syslang')), this.c.Decrypt(sessionStorage.getItem('brnId')))
      this.router.navigateByUrl(routeComponent)
    
    }
  }

  getUrl(path) {
    this.router.navigateByUrl(path)
  }
  
  getM(lang, brn) {
    this.ser.GetMenu('', brn, lang).subscribe(data => {
      this.MenuMain = data
      
    })
  }

}